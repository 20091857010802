.tutorialScreenOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
  }
  
  .tutorialScreenContainer {
    position: relative;
    width: 83.18vw;
    height: 92vh; /* Adjust the height to fit the screen */
    z-index: 2001;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Ensure content is aligned at the top */
    background: transparent;
  }
  
  .tutorialScreenImage {
    width: 95%;
    height: auto;
    border: 3px solid #FF00A0; /* Add border directly to the image */
  }
  
  .navigationButtons {
    position: absolute;
    bottom: 90px; /* Position just above the bottom */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .navButton {
    width: 60px;
    height: auto;
    cursor: pointer;
    margin: 0 20px;
  }
  
  .navButton.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  
  .progressIndicator {
    width: 70px;
    height: auto;
  }
  
  .gotItButton {
    position: absolute;
    bottom: 20px; /* Position below the navigation buttons */
    width: 75%;
    height: auto;
    cursor: pointer;
  }