.walletScreenOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1999;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
  }
  
  .walletScreenContainer {
    position: relative;
    width: 100%;
    max-width: 590px;
    height: 500px;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('../assets/WalletScreen.webp') no-repeat center center;
    background-size: contain;
    padding: 20px;
  }
  
  .walletCloseButton {
    position: absolute;
    top: 80px;
    right: 50px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 3002; /* Ensure it is above other content */
  }
  
  .walletScreenContent {
    position: relative;
    z-index: 3001;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
  }
  
  .walletInputContainer {
    position: relative;
    width: 80%;
    max-width: 300px;
    margin: 20px 0;
    z-index: 3001;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .walletInputButton {
    width: 100%;
    height: auto;
  }
  
  .walletInputField {
    position: absolute;
    width: calc(100% - 40px); /* Adjust as needed to fit within the button */
    height: 100%;
    left: 20px; /* Adjust to center within the button */
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: white;
    font-size: 18px;
    padding: 0;
    z-index: 3002;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .walletInputField::placeholder {
    color: white;
    opacity: 0.5;
  }
  
  .connectButtonContainer {
    position: relative;
    width: 80%;
    max-width: 300px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3001;
  }
  
  .connectButton {
    width: 100%;
    height: auto;
  }
  
  .connectButtonText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: white;
    text-align: center;
    pointer-events: none;
  }
  
  @media (max-width: 600px) {
    .walletTitle {
      font-size: 28px;
    }
    .walletInputField {
      font-size: 18px;
    }
    .connectButtonText {
      font-size: 18px;
    }
  }
  
  @media (max-width: 500px) {
    .walletTitle {
      font-size: 25px;
    }
    .walletInputField {
      font-size: 15px;
    }
    .connectButtonText {
      font-size: 15px;
    }
  }
  