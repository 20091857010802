.headerBar {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 110%;
  max-width: 600px;
  height: 90px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.headerButtonsContainer {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1500;
  padding: 0 10px;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.topLogo {
  height: 60px;
  width: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
}

.profileButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 60px;
  position: absolute;
  top: 6.5px;
  left: 10px;
  z-index: 1501;
}

.profileButtonText {
  text-align: center;
  font-size: 24px;
  color: white;
  pointer-events: none;
  max-width: 150px; /* Adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rightButtonsContainer {
  display: flex;
  align-items: center;
  position: absolute;
  top: 15px; /* Lowered slightly */
  right: 10px;
}

.walletButton,
.settingsButton {
  height: 40px;
  width: auto;
  margin-left: 10px;
}

/* Media query for smaller devices */
@media (max-width: 500px) {
  .headerBar {
    height: 90px;
  }

  .headerButtonsContainer {
    width: 100%;
  }

  .topLogo {
    height: 60px;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .profileButtonContainer {
    left: 10px;
  }

  .profileButtonText {
    font-size: 20px;
    color: white;
  }

  .rightButtonsContainer {
    top: 15px; /* Lowered slightly */
    right: 10px;
  }

  .walletButton,
  .settingsButton {
    height: 40px;
    width: auto;
    margin-left: 10px;
  }
}

/* Media query for larger devices */
@media (min-width: 600px) {
  .headerBar {
    height: 90px;
  }

  .headerButtonsContainer {
    width: 100%;
    max-width: 600px;
  }

  .topLogo {
    height: 60px;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .profileButtonContainer {
    left: 10px;
  }

  .profileButtonText {
    font-size: 24px;
    color: white;
  }

  .rightButtonsContainer {
    top: 15px; /* Lowered slightly */
    right: 10px;
  }

  .walletButton,
  .settingsButton {
    height: 40px;
    width: auto;
    margin-left: 10px;
  }
}
