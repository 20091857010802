.footerBar {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 110%; /* Full width */
  max-width: 600px; /* Maximum width */
  height: 85px; /* Fixed height */
  z-index: 1000; /* Ensure the footer is above other elements */
}

.footerButtonsContainer {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 110%; /* Full width */
  max-width: 590px; /* Maximum width */
  height: 65px;
  min-height: 50px;
  z-index: 1500; /* Ensure buttons are above the footer bar */
}

.footerButton {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70px;
  margin: 0 10px;
}

.footerButton img.buttonBackground {
  width: 100%;
  height: 75px; /* Ensure height can scale properly */
  max-height: 100px;
}

@media (max-width: 500px) {

  .footerBar {
    width: 110%; /* Full width */
  }
  .footerButton {
    margin: 0 5px;
    height: 70px;
    
  }
  .footerButtonsContainer {
    min-height: 55px;
    height: 65px;
    width: 100%;
  }

  .footerButton img.buttonBackground {
    height: 75px; /* Ensure height can scale properly */
  }
}

@media (max-width: 600px) {

  .footerBar {
    width: 110%; /* Full width */
  }
  .footerButton {
    height: 55px;
    margin: 0 5px;
  }
  .footerButtonsContainer {
    min-height: 55px;
    height: 70px;
    width: 100%;
  }

  .footerButton img.buttonBackground {
    height: 75px; /* Ensure height can scale properly */
}
}