/* Disable text selection and other interactions globally */
* {
  -webkit-user-select: none; /* Chrome, Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard-compliant browsers */
  -webkit-touch-callout: none; /* Prevent iOS from displaying the callout menu */
  -webkit-tap-highlight-color: transparent; /* Disable tap highlight color on mobile browsers */
  -webkit-app-region: no-drag; /* Disable drag regions in Electron apps */
  -khtml-user-select: none; /* Konqueror */
  -webkit-user-drag: none; /* Chrome, Safari */
  -moz-user-drag: none; /* Firefox */
  -ms-user-drag: none; /* IE 10+ */
}

/* Prevent zooming and scrolling */
body, html {
  overflow: hidden; /* Lock the page to prevent scrolling */
  -ms-touch-action: none; /* IE 10+ */
  touch-action: none; /* Standard-compliant browsers */
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden; /* Ensure that child elements cannot scroll */
}

/* Disable double tap to zoom */
@media (min-width: 0px) {
  html, body {
    touch-action: manipulation;
  }
}

/* Ensure images cannot be dragged */
img {
  -webkit-user-drag: none; /* Prevents image dragging */
}

/* Allow pointer events for specific interactive elements */
button, a, .boostButton, .energyCloseButton, .rechargeCloseButton, .multitapCloseButton, .settingsCloseButton, .earnCloseButton, .upgradeButtonImage, .energyScreenOverlay, .multitapScreenOverlay, .rechargeScreenOverlay, .settingsScreenOverlay, .earnPopScreenOverlay {
  pointer-events: auto;
}

/* Remove link underlines and ensure no purple underline on visited links */
a {
  text-decoration: none;
  color: inherit; /* Ensures the link text inherits the color of the surrounding text */
}

/* Specifically handle the Boost_Icon link to ensure no underlines */
a.boostLink {
  text-decoration: none;
  color: inherit;
}

/* Prevent zooming with meta tags in the head section */
body {
  -ms-touch-action: none; /* Disable zoom on IE */
  -ms-content-zooming: none; /* Disable zoom on IE */
  overflow: hidden !important; /* Ensure no scrolling */
}

/* Disable zooming with CSS */
html {
  touch-action: none;
  -webkit-text-size-adjust: none; /* Prevents text resizing on mobile browsers */
}

button, input, select, textarea {
  -webkit-user-select: none; /* Prevents text selection on mobile browsers */
}

/* Ensure the app stays in portrait mode */
html, body {
  transform: rotate(0deg); /* Reset any rotation */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media screen and (orientation: landscape) {
  html, body {
    transform: rotate(90deg); /* Rotate the content to stay in portrait mode */
    transform-origin: left top; /* Adjust the origin to the top left */
    width: 100vh; /* Adjust width and height to swap values */
    height: 100vw;
    overflow: hidden;
}

/* Hide header and footer when loading screen is active */
body.loading-active header,
body.loading-active footer {
  visibility: hidden; /* Make the header and footer invisible */
  pointer-events: none; /* Disable interactions */
}

/* Ensure nothing is clickable when loading screen is active */
body.loading-active .container {
  pointer-events: none;
}
}

/* Existing CSS rules here */

/* Add styles for the QR code container and image */
.qr-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #000000;
}

.qr-code-container h1 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.qr-code-image {
  max-width: 80%;
  max-height: 80%;
  height: auto;
  width: auto;
}

/* Styles for inactive screen */
.inactive-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #fff;
  color: #333;
}

.inactive-screen h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.inactive-screen p {
  font-size: 18px;
}

.scrollableInvestContainer,
.scrollableNewAccountsContainer {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}