@font-face {
  font-family: 'AdigianaUI';
  src: url('../assets/AdigianaUI.ttf') format('truetype');
  font-display: swap;
}

.new-loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.new-loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 90%;
  max-width: 500px;
}

.new-airdrop-container {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3vh;
}

.new-logo-airdrop {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  user-select: none;
}

.new-airdrop-title-text {
  font-family: 'AdigianaUI', Arial, sans-serif;
  font-size: 6vw;
  color: white;
  text-align: center;
  margin-bottom: 3vh;
}

.new-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.new-text-line {
  font-family: 'AdigianaUI', Arial, sans-serif;
  font-size: 2.5vw;
  color: white;
  margin: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-circle-image {
  width: 2.5vw;
  height: 2.5vw;
  margin-right: 2vw;
}

@media (min-width: 768px) {
  .new-airdrop-title-text {
    font-size: 46px;
  }

  .new-text-line {
    font-size: 19px;
  }

  .new-circle-image {
    width: 19px;
    height: 19px;
    margin-right: 15px;
  }
}

@media (max-width: 767px) {
  .new-airdrop-container {
    height: 25vh;
  }

  .new-airdrop-title-text {
    font-size: 8vw;
  }

  .new-text-line {
    font-size: 4vw;
  }

  .new-circle-image {
    width: 4vw;
    height: 4vw;
    margin-right: 3vw;
  }
}

@media (max-width: 480px) {
  .new-airdrop-container {
    height: 20vh;
  }

  .new-airdrop-title-text {
    font-size: 10vw;
  }

  .new-text-line {
    font-size: 5vw;
  }

  .new-circle-image {
    width: 5vw;
    height: 5vw;
    margin-right: 4vw;
  }
}