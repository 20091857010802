.settingsScreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1999;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
}

.settingsScreenContainer {
  position: relative;
  width: 100%;
  max-width: 590px;
  height: 500px;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('../assets/SettingsScreen.webp') no-repeat center center;
  background-size: contain;
  padding: 20px;
}

.settingsCloseButton {
  position: absolute;
  top: 80px;
  right: 50px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 3002; /* Ensure it is above other content */
}

.settingsScreenContent {
  position: relative;
  z-index: 3001;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: white;
}

.settingsTitle {
  font-size: 28px;
  font-weight: bold;
  margin-top: 60px;
  z-index: 3001;
}

.hapticButtonContainer, .tutorialButtonContainer {
  position: relative;
  width: 80%;
  max-width: 300px;
  margin: 10px 0; /* Reduced margin to bring the buttons closer */
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3001;
  cursor: pointer;
}

.hapticButtonImage, .tutorialButtonImage {
  width: 100%;
  height: auto;
  filter: brightness(0.8);
}

.hapticButtonImage.on {
  filter: brightness(2) hue-rotate(-90deg);
}

.hapticButtonImage.off {
  filter: brightness(2) hue-rotate(90deg);
}

.hapticButtonText, .tutorialButtonText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: white;
  text-align: center;
  pointer-events: none;
}

@media (max-width: 600px) {
  .settingsTitle {
    font-size: 28px;
  }
  .hapticButtonText, .tutorialButtonText {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .settingsTitle {
    font-size: 25px;
  }
  .hapticButtonText, .tutorialButtonText {
    font-size: 15px;
  }
}
