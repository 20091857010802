/* MainLayout.css */

.main-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-size: cover; /* Ensure the background covers the entire area */
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.content {
  flex: 1; /* Take up remaining space */
  overflow: auto;
}
