.App {
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@font-face {
  font-family: 'AdigianaUI';
  src: url('./assets/AdigianaUI.ttf') format('truetype');
}

body {
  font-family: 'AdigianaUI', sans-serif;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
}

.page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.1);
}